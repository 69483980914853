.breadcrumb-item:last-of-type .breadcrumb-item-link{
    text-decoration: none;
    color: #667797;
}
.breadcrumb-item:last-of-type:hover .breadcrumb-item-link{
    text-decoration: none;
    color: #667797;
    cursor: default;
}
.breadcrumb-item .breadcrumb-item-link{
    text-decoration: none;
    transition: color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out,border-color .25s ease-in-out;
    color: #667797;
}
.breadcrumb-item:hover .breadcrumb-item-link,.breadcrumb-item:active .breadcrumb-item-link,.breadcrumb-item:visited .breadcrumb-item-link{
    text-decoration: none;
    transition: color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out,border-color .25s ease-in-out;
    color: var(--main);
}
.breadcrumb{
    --bs-breadcrumb-divider: '>';
}