.bundelPack{
    background: rgba(246, 248, 251, 0.5);
    border-radius: 15px;
    padding: 15px;
}


.bundels{
    background: rgba(231, 233, 240, 0.5);
    
}
.image-container{
    overflow: hidden;
}

.image-container .iamge{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
}
.image-container .iamge:hover{

transform: scale(1.8);
}
.size-select{
    font-size: 0.85rem;
}
.title-select{
    font-size: 1.15rem;
    text-transform: capitalize;
    font-weight: 500;

}
.card-pro{
    position: relative;
    
}
.card-pro .modal{
    
    
}

.gift-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgb(255, 255, 255);
    background: rgb(75, 155, 252);
    font-size: 28px;
    width: 70px;
    height:42px;
    z-index: 999;
    line-height: 28px;
    transition: all 0.25s linear;
}

.next-btn{
    position: absolute;
    inset:  8px 0 0 8px;
    color: rgb(210, 60, 6);
    font-size: 28px;
    width: 28px;
    height:28px;
    z-index: 999;
    line-height: 28px;
    transition: all 0.25s linear;

}
.next-btn:hover{
    transform: scale(1.1);
    color: rgb(238, 126, 14);
    border-radius: 50%;
    cursor: pointer;
}
.next-btn.size-btn{
    inset:  40px 0px 0px 8px;
}