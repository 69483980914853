.bundelCard {
    padding: 0 8px;
    transition: all 0.35s ease-in-out;
}
.bundelCard .image-container{
    height: 30vh;
}
.bundelCard .image-container{
    height: 25vh;
    min-height: 250px;
}
.bundelCard .image-container .image{
    object-fit: cover;
    width: 100%;
    border-radius: 9px;
    box-shadow: 0 3px 5px rgba(244, 84, 84, 0.1);
    transition:all 0.35s ease-in-out;
    height: 100%;
}
.bundelCard .content-container .items-list{
    text-transform: capitalize;
    font-size: 1.1rem;
    color: #949393;
}

.bundelCard .content-container .title{
    margin-top: 5px;
    margin-bottom: 0.75rem;
    font-weight: 500;
    line-height: 1.2;
    color: #363c4c;
}
.bundelCard .content-container .items-list .item .links{
    text-decoration: none; 
    font-size: 0.75rem;
    color: #657591;
    transition:color 0.25s ease-in-out ;

}
.bundelCard .content-container .items-list .item:hover .links{
    text-decoration: none; 
    color: #fe696a;
    
}
.bundelCard .content-container .items-list .item:hover{
    cursor: pointer;
    background: #f1f0f0;
}
.bundelcard-pills{
    font-size: 0.75rem;
}
.bundelCard:hover{
    background: #f8efef;
    transform: scale(1.01);
}
