.bundelCard-container{
    background-color: rgb(247, 248, 249);
    padding: 10px ;
    border-radius: 25px;
    font-family: 'Cairo', sans-serif;
 
 
}
.bundelCard-container a {
    text-decoration: none ;
    font-family: 'Cairo', sans-serif;
    font-size: 1.1rem;
    
}
.image-container{
    position: relative;
    z-index: 999;
}
.image-container::after{
       position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0 0; 
    content: "";
    

}
