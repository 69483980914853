.cutomeTag{
    text-transform: capitalize;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    padding: 7px 18px !important;
    line-height: 14px;
    border-radius: 4px;

}
.pending{
background: rgba(255, 198, 0, 0.16);
color: #FFC600;
}

.paied{
    background: rgba(15, 183, 255, 0.16);
    color: #0FB7FF;
}
.picked{
    background: rgba(51, 24, 157, 0.16);
    color: #33189D;
}
.confirmed{
    background: rgba(40, 199, 111, 0.16);
    color: #28C76F;
}
