.my-orders {
  min-height: calc(100vh - 72px);
  position: relative;
}
.my-orders-img{
    background-size: cover;
    background-position: center center;
}
table td,
table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

thead th,
tbody th {
  color: #fff;
}

tbody td {
  font-weight: 500;
  color: rgba(255,255,255,.65);
}

.card {
  border-radius: .5rem;
}
.currency{
  opacity: 0.3;
}
.invoice-window .modal-body, .invoice-window .modal-body{
  position: absolute !important;
  z-index: -99 !important;
  left: 0 ;
  top: 0;
  opacity: 0;

}
.invoice-window-buttons{
  width: 60%;
}