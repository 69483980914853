.looder{
    width: 100 dvw;
    height: 100 dvh;
    overflow: hidden;
    position: absolute;
    inset: -90px 0 0 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    background: #eeeeee;
    justify-content: center;
}
.progress {
   width: 134.4px;
   height: 67.2px;
   border-radius: 224px 224px 0 0;
   -webkit-mask: repeating-radial-gradient(farthest-side at bottom ,#0000 0,#000 1px 12%,#0000 calc(12% + 1px) 20%);
   background: radial-gradient(farthest-side at bottom,#474aff 0 95%,#0000 0) bottom/0% 0% no-repeat
       #dbdcef;
   animation: progress-e37qus 2s infinite steps(6);
}
@keyframes progress-e37qus {
   100% {
      background-size: 120% 120%;
      color: 120% 120%
   }
}
.looder .brand{
    width: 134.4px;
    height: 67.2px;
    animation: color-e37qus 2s infinite steps(6);
    color: #dbdcef;

}
@keyframes color-e37qus {
    100% {
       color: #474aff
    }
 }
