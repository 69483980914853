:root
.light
{
  --main:#3734A9;
  }
.dark{
    --main:#434cee;
    --sec: #213d5c;
    --tha:#faf8fd;
    --sea:#ebeef2;
    height: 4000px;
    --bgc: rgb(23, 23, 23);
}
* {
  font-family: 'Baloo Tammudu 2',sans-serif;
}
body{
  overflow-x: hidden !important;

}
.link{
    color: inherit;
    text-decoration: none !important;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3734A9;
  --bs-btn-border-color: #3734A9;
  --bs-btn-hover-color: #fff;
  
  --bs-btn-hover-bg: #4d48e3;
  --bs-btn-hover-border-color: #4d48e3;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d48e3;
  --bs-btn-active-border-color: #6f93ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3734A9;
  --bs-btn-disabled-border-color: #3734A9;
}
.modal{
  --bs-modal-width: 75%;

}
.APP{

}
.modal-backdrop{
  z-index: 0;
}