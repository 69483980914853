.products-page{
    width: 98%;
    background: rgb(242, 248, 248);
 margin: 0 auto;

}
.products-page-cover{
    height: 25vh;
}
.products-page-cover-overlay{
    height: 98%;
    background:rgba(255, 163, 163, 0.533);
    border-radius: 10px;
}
.products-page-head{
    font-size: 4.5rem;
}
.controls{
}
.header-coll-controls{

}
.reset{
    margin: auto 0;
    font-size: 0.75rem;
    color: #5a5050;
    
}
.reset:hover{
    color: #000000;
    cursor: pointer;
}
.heade-coll-controls{
    font-size: 1.6rem;
    font-weight: 600;
}
.filter-item{
    font-size: 1.1rem;
    font-weight: 600;
}
.reset-filter{
    font-size: 0.6rem;
    background: rgb(244, 239, 239);
}
.card-wrapper{
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
