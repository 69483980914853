.Header{
    width: 100vw;
    height: 710px;
    background:linear-gradient(to bottom,#bab9f921,#fdfdfd);
    border: none;
    box-shadow: none;
    
}


/* button */
.purple-button{
    vertical-align: center;
    margin-top: 5rem;
    height: 3.1rem;
    font-size:1.25rem ;
    line-height: .875rem;
    border: 1px solid #5c58fc;
    background-color: var(--main);
    padding:1.2rem 2.5rem ;
    border-radius:15px ;
    color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 6px var(--main);
}
.purple-button:hover{
    color: rgb(255, 255, 255);

cursor: pointer;
background-color: #5c58fc;
transition:background 0.2s ease-in;
}

.purple-button:active
{
    background-color: var(--main);
    transform: scale(1);
}
.contect-header h2{
    margin:10% 0 0 0;
    font-size:40px ;
    font-weight: 600;
    line-height: 2;
    color: #5c58fc;

}
/*h3*/
.contect-header h3{
    margin:5% 0 0 0;
    font-size:25px ;
    font-weight: 100;
    line-height: 1.5;
    color: #2d29ba;

}

.carousel-indicators  #points{
    background: #5c58fcae;
    width: 20px ;
    height: 20px ;
    border: 0;
    margin:0 0.5rem ;
    padding: 0;
    border-radius: 50%;
}
.carousel-indicators  #points.active{
    background: #5c58fc;
    transition:background 0.5s linear ;
}
.Header .carousel-control-next,.Header .carousel-control-prev { 
    color: #5c58fc;
    font-size: 1.75rem;
}
.Header .carousel-control-next:hover,.Header .carousel-control-prev:hover { 
    color: #5c58fc;

}
.Header .carousel-control-next:hover span,.Header .carousel-control-prev:hover span{ 

    transform: scale(1.5);
    transition: all 0.25s linear;
}
.Header .carousel-control-prev { 

    background: linear-gradient(to left,var(--tha),#1e3de94e);
}
.Header .carousel-control-next{ 
    font-size: 1.75rem;
    background: linear-gradient(to right,var(--tha),#1e3de94e);
}
@media (max-width: 768px) {
    .Header{
        text-align: center;
        height: 90vh;
    }
    /* button */
    .purple-button{
        margin-top: 2rem;
        height: 2.1rem;
        font-size:1rem ;
        line-height: .30rem;
        padding:.82rem 3.5rem ;
        border-radius:10px ;
    }
    .purple-button:active
    {
        background-color: var(--main);
        transform: scale(0.75);
    }
    .contect-header h2{
        margin:10% 0 0 0;
        font-size:35px ;
        font-weight: 600;
        line-height: 2;
        color: #5c58fc;
    }
    /*h3*/
    .contect-header h3{
        margin:5% 0 0 0;
        font-size:20px ;
        font-weight: 100;
        line-height: 1.5;
        color: #9895f6;
    
    }
    .carousel-indicators  #points{
        background: #5c58fcae;
        width: 10px ;
        height: 10px ;
        border: 0;
        margin:0 0.5rem ;
        padding: 0;
        border-radius: 50%;
    }

}