.product-singlePage-head,.header-count-center{
color: var(--black, #000);
font-size: .75rem;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.product-page-header{
    background-color: #f8f8f8;
}
.product-singlePage-head{
}
.size-controls .custom-radio{
    width: 3.5rem;
    height: 3.5rem;
    color: var(--black, #000);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 68px;
    text-transform: uppercase;
}
.size-controls  .form-check.custom-radio .form-check-input{
    width: 3.5rem;
    height: 3.5rem;
    float: none;
    position: sticky;
}
.form-check.custom-radio .form-check-input:checked ,.form-check.custom-radio .form-check-input:focus,
.form-check.custom-radio .form-check-input:active
{
    color: #090808;
    background-color: rgb(245, 194, 194);
    border-color: #00000057;
} 
.count-center-counter .button-count{
    border-radius: 5px 0;
    height: 3rem;
    width: 2.5rem;
    color:#afa4a4;
    font-size: 2rem;
}
.count-center-counter .button-count:first-of-type{
    border-radius: 5px 0 0 5px;

}
.count-center-counter .button-count:last-of-type{
    border-radius: 0 5px 5px 0;

}
.counter{
    border-radius: 5px;
    height: 3rem;
    width: 2.5rem;
    display: inline-block;
    text-align: center;
    border: 2px solid #00000057;
    border-width:  2px 0 ;
    font-size: 1.5rem;
    border-radius: 0;
    line-height: 3rem;
    background: #a84b4b;
}
.header-count-total{
    color: var(--black, #000);
    font-size: 1.8rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.product-page .slick-dots  {
    position: static !important;
    bottom: -120px;
}
.product-page .slick-dots  li{
    width: 80px !important;
    height: 120px !important;
}