.NotFoundPage{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to left,rgba(189, 241, 255, 0.5),rgba(246, 197, 197, 0.5));
}
.head-not{
    font-size: 12rem;
    color: rgb(45, 76, 103);
}
.subhead-not{
    font-size: 2rem;
    color: rgba(71, 106, 136, 0.807);
}
.paragraph-not{
    font-size: 0.75rem;
    color: rgba(102, 121, 137, 0.61);

}