.navbar-brand.custom{
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: var(--sec);
    font-family: 'Abril Fatface', cursive;
    height: 35px;
    
}
.navbar-brand span{
    color: var(--main);
    font-family: 'Abril Fatface', cursive;

}
.dropdown .icon{
    width: 38px;
    height: 38px;
    display: inline-block;
    border-radius: 10px;
    background: linear-gradient(to right,#808bfb , #4d5bee );
   
}

.nav.custom{
    font-size: 1rem !important;
    font-weight: 400;
    }
.nav.custom li{
    line-height: 1.5rem;
    border-bottom: 2px solid #ffffff00;
        border-radius: 1.5px;
        color: var(--sea);
        transition: all ease-in-out 0.25s;
    }
    .nav.custom li:hover{
        border-bottom: 2px solid #ee434c;
        color: var(--sec);
       cursor: pointer;
    }
    .nav.custom  a{
        color: var(--sea);
    }
    .nav.custom  a:hover{ 
           color: var(--sec);
    }
    .nav.custom  .nav-link.active{ 
           color: var(--main);
    }

   .cart a.icon{
        font-size: 25px;
        color: var(--main);
    }
   .cart a.icon:hover,
   .cart a.icon.show
   {
        font-size: 25px;
        color: var(--main);
        opacity: 0.80;
    }
    .bubble{
        width: 20px;
        height:20px;
        background-color: rgb(254, 140, 140);
        border-radius: 50%;
        color: white;
        font-size: 0.75rem;
        line-height: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        inset: 00% 60%;
        z-index: 1;
    }
    .cart{
        position: relative;
    }


    .flycart.cart{
        position: fixed;
        bottom: 50px;
        right: 50px;
        width: 50px;
        height: 50px;
        background: #e6e8f9da;
        border-radius: 50%;
        box-shadow: 0px 0px 15px #e6e8f9da;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999999;
    }

@media (max-width:770px){
    .flycart.cart{
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 45px;
        height: 45px;
        background: #e6e8f9da;
        border-radius: 50%;
        box-shadow: 0px 0px 5px #e6e8f9da;
        display: flex;
        font-size: 1.1rem;

        justify-content: center;
        align-items: center;
        z-index: 999999;
    }
    .prof{
        position: relative;
    }
   .prof .dropdown-menu{
        display: block !important;
        width: 100% ;
        position: static;
        border: 0;
        background: rgb(237, 245, 253);
        border-bottom:  1px solid rgb(76, 144, 235);
        z-index: 99999;
        opacity: 1;
        margin-top: 5px;

    }
}
.wave-icon{
 color: rgb(234, 193, 11);
}
