.form .form-check-label{
    font-size: 0.75rem;

}
.form .form-check-input{
    font-size: 0.75rem;
    margin:  6px  2px;
}
.form .form-check-input:checked {
    background-color: #000000;
    border-color: #48494b;
}
.form .form-check-input:active,.form .form-check-input:hover {
    background-color: #89838379;

}