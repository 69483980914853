.bundel{
    background-color: #ffffff;
}
.bundel .card-product{
    box-shadow:none;
}
.bundel-header{
    margin: 0.75rem 0;
    height: 15vh;
    background-color: #f6f9fc;
    padding: .25rem 0.5rem;

}
.header-page{
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.75rem;
    color: #5f729b;
}