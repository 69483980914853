.cartPage .header-cart{
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(92, 89, 89);   
}
.content-cart .image-product-cart{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}
.content-cart .image-product-cart-container{
    height: 150px;
}
.invoice-order{
    width: 0;
    height: 0;
    overflow: hidden;
}
.actions .action-icons{
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgb(70, 108, 110);
    padding:0.12rem  0.1rem ;
    background: #E6F1FA;
    border-radius: 5px;
}
.actions .action-icons:first-of-type:hover{
  color: rgba(237, 46, 46, 0.921);
    background: #E6F1FA;
    cursor: pointer;
}

.actions .action-icons:hover{
  color: rgba(84, 46, 237, 0.921);
    background: #E6F1FA;
    cursor: pointer;
}
.recipte{
    width: 98%;
    padding: 2rem 1rem;
    height: 100%;
    background: #F0F2F2;
    border: 1px solid rgb(84, 88, 171);
}
.recipte-headers{
    font-size: 1rem;
    font-weight: 400;
}
.nameOP{
    font-size: 1rem;
    color: #1e1e1e;
    
}
.nameOP:last-of-type{
    font-size: 1rem;
    color: #1e1e1e;
}
.cart-header{
    color: #ffffff;
    width: 100%;
    height: 120px;
    border-radius: 15px;
    background: linear-gradient(to left,rgb(250, 161, 161),rgb(197, 197, 235));
}
.empty{
    width: 50%;
    height: 50%;
}
.empty h1{
    text-align: center;
    font-size: 3rem;
    color: #c5daec;
}
.invoice-window{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 999;
    background: rgba(169, 206, 239, 0.866);
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(0px);
    overflow: hidden;
}
.invoice-window-body{
    padding: 5px 5px 5px 5px;
    background-color: #ffffff;
    border-radius: 5px;

}
