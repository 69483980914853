.login{
    position: relative;
    background-position: right center;
    width: 100vw;
    height: 100vh;
    
}
.login-hlo{
    height: 100%;
}
.login-form{
    background:rgb(240, 240, 240);
    border: 1px solid rgba(255, 255, 255);
    box-shadow: 2px 12px 20px rgba(176, 224, 255, 0.8);
    padding:10vh  30px;
    border-radius: 0px;
    height: 100%;
  
}
.welcome-head{
    text-align: left;
    text-transform: capitalize;
    margin-left: 2px;
    color: #100168e8;
}
.welcome-parag{
    text-align: left;
    margin-left: 2px;
    color: #b3a8f5e8;
    
}
.login .navbar-brand.custom{
    color: rgb(255, 255, 255);
    font-size: 5.5rem;
    line-height: 0;

}
.login .navbar-brand.custom .brand-u{
    color: #3834a9;
}
.avtar{
    font-size: 5.5rem;
    color: #3834a96b;
}
.bg-image{
    background-image: -webkit-linear-gradient(to right, #9c99ffab, #3834a9b6), url('../../assets/imgs/loginbg1.jpg');  /* Chrome 10-25, Safari 5.1-6 */
    background-image:linear-gradient(to right, #9c99ff, #3834a9e4), url('../../assets/imgs/loginbg1.jpg'); ; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: cover;
    background-repeat: repeat-x;
    height: 100%;
    background-position:  50% 50%;
}