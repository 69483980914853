.upper{
    height: 120px;
    background: linear-gradient(to right, rgba(97, 106, 212, 1), rgba(76, 237, 154, 0.363));
    border-radius: 15px 15px 0 0;
}
.rounded-avtar{
    height:100px;
    background: rgb(9, 5, 56);
    border-radius: 10px;
    
}

.upper .name{
    color: white;
    font-size: 1.4rem;
    font-weight: 400;
}
.upper .id{
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.1rem;
    font-weight: 400;
}
.upper .butt{
    filter: brightness(1.5);
    letter-spacing: 1.2px;
}