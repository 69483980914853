.card-product .accordion-item{
    border: 0;
    background: transparent;
    position: relative;
    z-index: 1;
}

.card-product{
    width: 100%;
    border-radius: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    

}
.image-container{
    height: 440px;
    width: 100%;
    overflow: hidden;
    background: white;
    border-radius: 25px 25px 0px 0px;
}

.product-image{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 25px 25px 40px 40px;
    height: 100%;


    
    


   
    box-shadow: (0px 1px 4px rgba(18, 18, 18, 0.301));
}


.card-content{
    background: white;
    width: 100%;
    bottom: -160px;
}
.tag-product{
font-weight: 500;
font-size: 12px;
line-height: 22px;
color: rgba(18, 18, 18, 0.454);
}
.name-product{
font-weight: 600;
font-size: 20px;
line-height: 20px;
color: #350096;
}
.price-product{
font-weight: 700;
font-size: 24px;
line-height: 18px;
color: #3e08a197;
}
.price-product span{
font-size: 11px;
line-height: 10px;
color: #262626a8;
}
.accordion-item .collapsed-card{
    background: rgb(255, 255, 255);
    width: 100%;
    top: 95%;
    z-index: -1;
    padding: 0.5rem 0;
}
.collapsed-card .fix{
    height: 25px;
    width: 100%;
}
.button-count{
    width: 25px;
    height: 25px;
    font-size: 1.1rem;
    line-height: 1.1rem;
    text-align: center;
    padding: 0;
    border-color: #6b6868 !important;
}
.form-check.custom-radio {
    width: 1.8rem;
    height:1.8rem;
    border-radius: 10px;
    background: #ffffff;
    position: relative;
}
.form-check.custom-radio .form-check-input {
    position: absolute;
width: 1.8rem;

height:1.8rem;
background: #ffffff;
border-radius: 10px ;
border-color: #b1b1b1;
}
.form-check.custom-radio .form-check-input:checked ,.form-check.custom-radio .form-check-input:focus,
.form-check.custom-radio .form-check-input:active
{
    color: #090808;
    background-color: rgb(255, 255, 255);
    border-color: #090808;
} 
.form-check.custom-radio .form-check-label{
    position: absolute;
    inset: 10% 0%;
    cursor: pointer;
    color: #b1b1b1;
    text-align: center;
    width: 100%;
    line-height: 0.7rem;
    font-size: 0.75rem;
    height: 100%;

}
.form-check.custom-radio .form-check-input:checked ~ label.form-check-label
{
    color: #090808;
} 
.card-content{
    padding-left:0.5rem ;
    border-radius:0  0 30px 30px!important;
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    padding-bottom: 20px;
}
.collapsed .card-content{
    border-radius:0 0 30px 30px!important;
}
.favorate-icon{
    opacity: 1;
    position: absolute;
    top: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 19;
    right: 15px;
    background: #f4e5e5dd;
    transition: all 0.5s ease;
    color: var(--main);
    font-size: 1.25rem;
    line-height: 1.8rem;
    text-align: center;
    transition: all 0.5s ease-in-out;
    
}
.card-product{
    position: relative;

}
.favorate-icon:hover{
    background: #f3efefdd;
    color: var(--main);
    transform: scale(1.1);
    cursor: pointer;


} 