
.page-header{
    background: #2c33b4;
    height: 25vh;
    padding-top: 5vh;
    color: #ffff;
}
.page-header .header{
    font-size:1.75rem;
    color: #ffff;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.2;
}
.page-header .breadcrumb-item .breadcrumb-item-link,.page-header  .breadcrumb-item+.breadcrumb-item::before  {
    color: #ffffffe4;
}
.page-header .breadcrumb-item:last-of-type .breadcrumb-item-link {
    color: #b8b9bae4;
}
.SingleBundel{
    position: relative;
  
}
.page-content{
    position: absolute;
    border-radius: 10px;
    padding:10px 10px ;
    background-color: #ffffff;
    top: 15vh;
    
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2);
}
.product  .iamge-container-product{
    border-radius: 15px;
    height: 220px;
    border: 3px solid rgb(241, 240, 240);
}
.coveriamge{
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.bundel-controls .price{
    font-size: 1.5rem;
    font-weight: 600;
    color: #252862;
}
.product {
    padding:10px 10px ;
    background-color: #f1f1f1;
    z-index: 1;
    border-radius: 7px;
}
.list-selected-sizes{
    transition: all 0.35s ease;
}

@media (max-width: 767.98px) {  

    .page-header{
        height: 55vh;
    }
    .page-content{
        top: 30vh;
    }

}
